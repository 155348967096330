import React from 'react';
import styled from 'styled-components';
import {LINKS, SHOP_INFO, BREAK_POINTS} from '../../Domain/Constants';
import {LogoFacebook} from '@styled-icons/ionicons-solid/LogoFacebook';
import {LogoInstagram} from '@styled-icons/ionicons-solid/LogoInstagram';
import {Pin} from '@styled-icons/ionicons-outline/Pin';
import imgLogoBlock from '../../images/logo-block.png';
import {Input, message} from 'antd';
import Button from '../../Components/Button';
import {useOutlet} from 'reconnect.js';
import * as FormatValidator from '../../Utils/FormatValidator';

const CONTACT_DISPLAY_GROUPS = {
  group1: [
    {title: '姓名', key: 'name'},
    {title: '電子信箱', key: 'email'},
    {title: '手機號碼', key: 'phone', placeholder: '0912345678'},
  ],
  group2: [
    {title: '訂單編號', key: 'order_id'},
    {title: '詢問內容', key: 'content', type: 'textarea'},
  ],
};

function ContactPage(props) {
  const [actions] = useOutlet('actions');
  const [values, setValues] = React.useState({});
  const _onInputChange = React.useCallback(
    (key) => (e) => {
      setValues({
        ...values,
        [key]: e.target.value,
      });
    },
    [values],
  );

  const _onSubmit = React.useCallback(async () => {
    console.log(values);
    if (
      !values.name ||
      !values.email ||
      !values.phone ||
      !values.order_id ||
      !values.content
    ) {
      message.error('請確認所有欄位都已填寫！');
      return;
    }

    if (!FormatValidator.isEmail(values.email)) {
      message.error('請檢查電子信箱格式');
      return;
    }

    if (!FormatValidator.isMobileNumber(values.phone)) {
      message.error('請檢查手機號碼格式');
      return;
    }

    try {
      actions.setLoading(true);
      await actions.postContactForm(values);
      message.success('表單已送出！');
    } catch (err) {
      message.error('無法送出表單！');
    } finally {
      actions.setLoading(false);
    }
  }, [values]);

  return (
    <Wrapper>
      <div className="content">
        <div className="info">
          <img src={imgLogoBlock} alt="dotdot-logo" />
          <h2>
            小和好點
            <br />
            dot.dot. Bakery & Cafe
          </h2>
          <h3>肉桂捲專門店</h3>
          <div className="socials">
            <a
              className="social"
              href={LINKS.instagram}
              target={'_blank'}
              rel="noreferrer">
              <LogoInstagram color={'#000'} size={24} />
            </a>
            <a
              className="social"
              style={{marginLeft: 20}}
              href={LINKS.facebook}
              target={'_blank'}
              rel="noreferrer">
              <LogoFacebook color={'#000'} size={24} />
            </a>
          </div>
          <div className="meta">
            <Pin color={'#000'} size={16} style={{marginRight: 5}} />{' '}
            {SHOP_INFO.address}
          </div>
        </div>

        <div className="form" style={{marginTop: 50, marginBottom: 30}}>
          <h2>聯絡我們</h2>
          <div
            className="form-content"
            style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <div className="col" style={{marginLeft: 0}}>
              {CONTACT_DISPLAY_GROUPS.group1.map((item, idx, arr) => (
                <InputField
                  key={idx}
                  style={{marginBottom: idx === arr.length - 1 ? 0 : 15}}>
                  <label>{item.title}</label>
                  <Input
                    onChange={_onInputChange(item.key)}
                    value={values[item.key]}
                    placeholder={item.placeholder || ''}
                  />
                </InputField>
              ))}
            </div>

            <div className="col">
              {CONTACT_DISPLAY_GROUPS.group2.map((item, idx, arr) => (
                <InputField
                  key={idx}
                  style={{marginBottom: idx === arr.length - 1 ? 0 : 15}}>
                  <label>{item.title}</label>
                  {item.type === 'textarea' ? (
                    <Input.TextArea
                      style={{height: 104}}
                      onChange={_onInputChange(item.key)}
                      value={values[item.key]}
                      placeholder={item.placeholder || ''}
                    />
                  ) : (
                    <Input
                      onChange={_onInputChange(item.key)}
                      value={values[item.key]}
                      placeholder={item.placeholder || ''}
                    />
                  )}
                </InputField>
              ))}
            </div>
          </div>
          <div className="footer">
            <Button
              type={'secondary'}
              style={{marginTop: 20}}
              onClick={_onSubmit}>
              送出訊息
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const InputField = styled.div`
  & > label {
    margin-bottom: 3px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
  }

  & input[disabled] {
    background-color: transparent;
    color: #000;
  }
`;

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    padding-top: 0;
    padding-bottom: 80px;
    & > .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      & img {
        width: 150px;
        height: 150px;
        margin-bottom: 30px;
      }

      & h2 {
        font-size: 20px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
        margin-bottom: 10px;
      }

      & h3 {
        margin-bottom: 50px;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 25px;
      }

      & .socials {
        display: flex;
        border-top: 1px solid #846e4f;
        border-bottom: 1px solid #846e4f;
        padding: 25px 20px;

        & > .social {
        }
        & > .social:hover {
          opacity: 0.6;
        }
      }

      & .meta {
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 25px;

        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 100px;
      }
    }
    & > .form {
      padding: 30px 50px;
      border: 1px solid #dddddd;
      border-radius: 10px;

      & > h2 {
        font-size: 20px;
        letter-spacing: 0;
        padding-bottom: 10px;
      }

      & > .form-content {
        border-top: 1px solid #846e4f;
        border-bottom: 1px solid #846e4f;

        padding-bottom: 27px;
        padding-top: 15px;

        & > .col {
          margin-left: 20px;
          flex-basis: calc((100% - 20px) / 2);
          @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
            flex-basis: 100%;
            margin-left: 0px;
            margin-bottom: 15px;
          }
        }
      }
      & > .footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export default ContactPage;
